var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("排课学生"),
          ]),
        ]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.dataList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "学号", align: "center", prop: "loginName" },
              }),
              _c("el-table-column", {
                attrs: { label: "姓名", align: "center", prop: "name" },
              }),
              _c("el-table-column", {
                attrs: { label: "性别", align: "center", prop: "sex" },
              }),
              _c("el-table-column", {
                attrs: { label: "来源", align: "center", prop: "source" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }