var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-row", [
        _c("div", { staticClass: "backTop" }, [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.gotoBack("cancel")
                },
              },
            },
            [
              _c(
                "i",
                {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { cursor: "pointer" },
                },
                [_vm._v("返回 | 预约试听")]
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "formBox" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.roomRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "试听学员：", prop: "student" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "240px" },
                      attrs: {
                        placeholder: "请选择试听学员",
                        "collapse-tags": "",
                        filterable: "",
                        multiple: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.form.student,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "student", $$v)
                        },
                        expression: "form.student",
                      },
                    },
                    _vm._l(_vm.dataList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "5px" },
                      attrs: { type: "text" },
                      on: { click: _vm.openAddModal },
                    },
                    [_vm._v("创建新学员")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排课方式：", prop: "radio" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.radioChange },
                      model: {
                        value: _vm.form.radio,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "radio", $$v)
                        },
                        expression: "form.radio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("加入已有试听排课"),
                      ]),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("新排课"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.radio == "3"
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "inputFocus",
                      attrs: { label: "试听课节：", prop: "treeData" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selectStyle",
                          staticStyle: { width: "240px" },
                          attrs: {
                            placeholder: "请选择试听课节",
                            clearable: "",
                          },
                          on: { change: _vm.selectChange },
                          model: {
                            value: _vm.form.treeData,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "treeData", $$v)
                            },
                            expression: "form.treeData",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            {
                              staticStyle: {
                                height: "auto",
                                "font-weight": "400",
                                background: "#ffffff",
                              },
                              attrs: { value: _vm.treeDataValue },
                            },
                            [
                              _c("el-tree", {
                                ref: "tree",
                                attrs: {
                                  data: _vm.data,
                                  "default-expand-all": "",
                                  "node-key": "id",
                                  props: _vm.defaultProps,
                                },
                                on: { "node-click": _vm.handleNodeClick },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.radio == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "试听排课：", prop: "curriculum" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.chooseLessonDialog = true
                            },
                          },
                        },
                        [_vm._v("选择排课")]
                      ),
                      _vm.isTagShow && _vm.form.curriculum !== ""
                        ? _c(
                            "el-tag",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: { closable: "", type: "info" },
                              on: { close: _vm.tagDel },
                            },
                            [_vm._v(" " + _vm._s(_vm.form.curriculum) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.radio == "3"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上课时间：", prop: "start_time" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { float: "left", width: "240px" },
                        attrs: {
                          type: "datetime",
                          "picker-options": _vm.expireTimeOption,
                          format: "yyyy-MM-dd HH:mm",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择上课时间",
                        },
                        on: { change: _vm.startTimeChange },
                        model: {
                          value: _vm.form.start_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "start_time", $$v)
                          },
                          expression: "form.start_time",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.radio == "3"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "授课老师：", prop: "teacher" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: {
                            placeholder: "请选择授课老师",
                            filterable: "",
                            clearable: "",
                            "collapse-tags": "",
                          },
                          model: {
                            value: _vm.form.teacher,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "teacher", $$v)
                            },
                            expression: "form.teacher",
                          },
                        },
                        _vm._l(_vm.allList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name + " [" + item.loginName + "]",
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: {
                        click: function ($event) {
                          return _vm.gotoBack("cancel")
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addStudent("form")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AddModal", { ref: "add", on: { submit: _vm.addMember } }),
      _c("choose-student", {
        attrs: {
          show: _vm.dialogChooseStudent,
          pageSource: "addadudition",
          originStudent: _vm.studentMessage,
        },
        on: {
          chooseStudent: _vm.chooseStudent,
          close: function ($event) {
            _vm.dialogChooseStudent = false
          },
        },
      }),
      _c("add-adudition-detail", {
        attrs: {
          show: _vm.addAduditionDetailDialog,
          aduditionObject: _vm.aduditionObject,
          isNewAdudition: _vm.isNewAdudition,
        },
        on: {
          close: function ($event) {
            _vm.addAduditionDetailDialog = false
          },
          aduditionDetail: _vm.aduditionDetailFun,
        },
      }),
      _c("chooseadudition-lesson", {
        attrs: {
          show: _vm.chooseLessonDialog,
          originLesson: _vm.aduditionDetail,
        },
        on: {
          close: function ($event) {
            _vm.chooseLessonDialog = false
          },
          chooseLesson: _vm.chooseLesson,
        },
      }),
      _c("sele-teacher", {
        attrs: { teacherId: _vm.form.teacherId, show: _vm.selectTeacherDialog },
        on: {
          teacherData: _vm.teacherData,
          close: function ($event) {
            _vm.selectTeacherDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }