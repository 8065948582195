<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span style="font-size: 16px;">选择学员</span>
    </div>
    <el-row>
      <el-col :span="8"  style="margin-bottom: 20px;">
      <el-input placeholder="请输入内容" v-model="nameorcard"  @keyup.enter.native="search()" >
        <el-select
          style="width: 100px;"
          v-model="select"
          slot="prepend"
          placeholder="请选择"
        >
          <el-option label="姓名" value="1"></el-option>
          <el-option label="学号" value="2"></el-option>
        </el-select>
      </el-input>

      </el-col>
      <el-col :span="8" :offset="1">
        <el-button type="primary" @click="search">查询</el-button>
      </el-col>
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
      >
        <el-table-column label="姓名" align="center" prop="name"></el-table-column>
        <el-table-column label="学号" align="center" prop="loginName"></el-table-column>
        <!-- <el-table-column label="校区" align="center" prop="campusName"></el-table-column> -->
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" v-if="originStudent.loginName !== scope.row.loginName" @click="chooseStudent(scope.row)">选择</el-button>
            <el-button type="success" size="mini"  v-if="originStudent.loginName === scope.row.loginName">已选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-row>
  </el-dialog>
</template>

<script>
import {getStudentList} from '@/api/member/student'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    originStudent: {
      default: {},
      type: Object
    },
    pageSource: {
      default: "",
      type: String
    }
  },
  data () {
    return {
      dataList: [],
      select:"1",
      nameorcard:"",
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  methods: {
    chooseStudent(row){
      this.$emit("chooseStudent",row)
      this.close()
    },
    openDialog () {
          this.nameorcard = "",
          this.select = "1",
          this.getStudentList();
    },
     async getStudentList() {
     const response = await getStudentList({
       pageNum:this.pagination.currentPage,
       pageSize: this.pagination.pageSize,
       currentStateSet: ["试听","在读"],
       needCount:true,
       name:this.select === "1" ?this.nameorcard:"",
       loginName:this.select === "2" ?this.nameorcard:"",
     })
      this.pagination.total = response.body.total
      this.dataList =  response.body.list
    },
    close () { this.$emit('close') },
    search(){
      this.pagination.currentPage = 1
      this.getStudentList()
    },
    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getStudentList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getStudentList()
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/dialog.scss";
@import "../../assets/font/iconfont.css";
.centerCls {
  display: flex;
  align-items: center
}
p {
  line-height: 30px;
}
</style>
