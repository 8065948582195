import $axios from '@/utils/request'
/**
 * 查询商品包列表
 * @param {*} data 
 * @returns 
 */
export function getCourseGoodsNameList(data) {
    const url = '/merchant/course_goods/list'
    return $axios.fGet(url, data)
}
/**
 * 查询商户购买的收费课程列表
 * @param {*} data 
 * @returns 
 */
export function getCourseGoodsList(data) {
    const url = '/merchant/course/user/log/list'
    return $axios.fGet(url, data)
}

/**
 * 根据课程获取未报名学员列表
 * @param {*} data 
 * @returns 
 */
export function getStudentListForCourse(data) {
    const url = '/merchant/course/user/listStudentForCourse'
    return $axios.fPost(url, data)
}
/**
 * 根据课程获取未报名学员列表
 * @param {*} data 
 * @returns 
 */
export function getStudentCourseUnitDetail(data) {
    const url = '/merchant/courseUnit/list_with_student'
    return $axios.fPost(url, data)
}
/**
 * /cms/merchant/course/user/list
 * @param {*} data 
 * @returns 
 */
export function getCourseMemberList(data) {
    const url = '/merchant/course/user/list'
    return $axios.fGet(url, data)
}
/**
 * 查询商户购买的收费课程列表
 * @param {*} data 
 * @returns 
 */
export function getCourseList(data) {
    const url = '/merchant/course/getCourseListByMerchant'
    return $axios.fPost(url, data)
}
/**
 * /cms/merchant/course/enterCourse
 * /cms/merchant/course/user/enterCourse
 * @param {*} data 
 * @returns 
 */
export function setCourseMember(data) {
    const url = '/merchant/course/user/enterCourse'
    return $axios.fPut(url, data)
}
/**
 * 查询课程列表
 * @param {*} data 
 * @returns 
 */
export function getCourseLists(data) {
    const url = '/merchant/course/list'
    return $axios.fGet(url, data)
}

/**
 * 查询排课课程列表
 * @param {*} data 
 * @returns 
 */
export function getListCourseOnly(data) {
    const url = 'merchant/course/listCourseOnly'
    return $axios.fGet(url, data)
}
/**
 * 查询老师试听列表
 * @param {*} data 
 * @returns 
 */
export function getListTryLesson(data) {
    const url = '/edu/teacherLesson/listTryLesson'
    return $axios.fPost(url, data)
}

/**
 * /cms/merchant/course/user/leaveCourse
 * @param {*} data 
 * @returns 
 */
export function leaveCourse(data) {
    const url = '/merchant/course/user/leaveCourse'
    return $axios.fPut(url, data)
}
/**
 * 
 * @param {*} data 
 * @returns 
 * /cms/merchant/course/user/log/list
 */
export function getStudentCourseLogList(data) {
    const url = '/merchant/course/user/log/list'
    return $axios.fGet(url, data)
}