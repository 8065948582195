<template>
  <div>
    <el-row>
      <div class="backTop">
        <span @click="gotoBack('cancel')">
          <i class="el-icon-arrow-left" style="cursor: pointer">返回 | 预约试听</i>
        </span>
      </div>
    </el-row>
    <div class="formBox">
      <el-form ref="form" :model="form" label-width="120px" :rules="roomRules">
      <el-form-item label="试听学员："  prop="student" >
        <el-select
          v-model="form.student"
          style="width:240px"
          placeholder="请选择试听学员"
          collapse-tags
          filterable
          multiple
          clearable
        >
          <el-option
            v-for="item in dataList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button type="text" @click="openAddModal" style="margin-left:5px">创建新学员</el-button>
      </el-form-item>

      <el-form-item label="排课方式：" prop="radio">
        <el-radio-group v-model="form.radio" @change="radioChange">
          <el-radio label="1">加入已有试听排课</el-radio>
          <el-radio label="3">新排课</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="试听课节：" class="inputFocus" prop="treeData"  v-if="form.radio == '3'" >
        <el-select
          class="selectStyle"
          v-model="form.treeData"
          placeholder="请选择试听课节"
          style="width:240px"
          clearable
          @change="selectChange"
        >
          <el-option
            :value="treeDataValue"
            style="height: auto;font-weight: 400;background: #ffffff;"
          >
            <el-tree
              ref="tree"
              :data="data"
              default-expand-all
              node-key="id"
              :props="defaultProps"
              @node-click="handleNodeClick"
            />
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="试听排课："  v-if="form.radio == '1'" prop="curriculum">
          <el-button
            type="text"
            @click="chooseLessonDialog = true"
            style="margin-right:5px"
            >选择排课</el-button
          >
          <el-tag style="cursor: pointer;"
          closable type="info" v-if="isTagShow && form.curriculum !== ''"
          @close="tagDel">
            {{ form.curriculum }}
          </el-tag>
      </el-form-item>
      <el-form-item label="上课时间：" prop="start_time" v-if="form.radio == '3'" >
        <el-date-picker
          style="float: left;width:240px"
          v-model="form.start_time"
          type="datetime"
          :picker-options="expireTimeOption"
          format="yyyy-MM-dd HH:mm"
          @change="startTimeChange"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择上课时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="授课老师：" prop="teacher"  v-if="form.radio == '3'">
        <el-select
          v-model="form.teacher"
          style="width:240px"
          placeholder="请选择授课老师"
          filterable
          clearable
          collapse-tags
        >
          <el-option
            v-for="item in allList"
            :key="item.id"
            :label="item.name+' ['+item.loginName+']'"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <!-- class="dialog-btn1" -->
        <el-button @click="gotoBack('cancel')" type="info" >取消</el-button>
        <el-button type="primary" @click="addStudent('form')" >提交</el-button>
      </el-form-item>
    </el-form>
    </div>


    <AddModal ref="add" @submit="addMember" />
    <choose-student
      :show="dialogChooseStudent"
      pageSource="addadudition"
      :originStudent="studentMessage"
      @chooseStudent="chooseStudent"
      @close="dialogChooseStudent = false"
    ></choose-student>
    <add-adudition-detail
      :show="addAduditionDetailDialog"
      :aduditionObject="aduditionObject"
      @close="addAduditionDetailDialog = false"
      @aduditionDetail="aduditionDetailFun"
      :isNewAdudition="isNewAdudition"
    ></add-adudition-detail>
    <chooseadudition-lesson
      :show="chooseLessonDialog"
      :originLesson="aduditionDetail"
      @close="chooseLessonDialog = false"
      @chooseLesson="chooseLesson"
    ></chooseadudition-lesson>
    <sele-teacher :teacherId="form.teacherId"  @teacherData="teacherData" :show="selectTeacherDialog" @close="selectTeacherDialog=false"></sele-teacher>
  <!-- </el-dialog> -->
  </div>
</template>

<script>
import seleTeacher from '@/components/frontbusiness/sele-teacher'
import AddModal from "@/components/add-student/AddModal";
import chooseaduditionLesson from "@/components/frontbusiness/chooseaduditionLesson";
import chooseStudent from "@/components/frontbusiness/choosestudent";
import addAduditionDetail from "@/components/frontbusiness/auditionManage/addAduditionDetail";
import { addStudentLesson } from "@/api/frontbusiness";
import { addStudent} from "@/api/member/student";
import { getEmployeeList } from "@/api/member/employee";
import {getStudentList} from '@/api/member/student'
import { formatTate } from '@/utils/formatDate'
import { $getTreeCourseThird,lessonTryAdd } from '@/api/senate/lesson'
// import {getListCourseOnly as getCourseListAll} from '@/api/businesscourse/course'

export default {
  name: "addAdudition",
  props: {
    pageType: {
      default: "123",
      type: String,
    },
    show: {
      default: true,
      type: Boolean,
    },
    isNewAdudition: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    form () {
      if (this.show) {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      }
    },
  },
  components: {
    AddModal,
    chooseStudent,
    addAduditionDetail,
    chooseaduditionLesson,
    seleTeacher,
  },
  data() {
    return {
      isTagShow:true,
      form:{
        radio: '1',
        treeData:'',
        student:[],
        curriculum:'',
        start_time:'',
        teacher:'',
        studentIds:'',
      },
      aduditionDetail: {},
      aduditionObject: null,
      studentMessage: {},
      chooseLessonDialog: false,
      stuDialog: false,
      dialogChooseStudent: false,
      addAduditionDetailDialog: false,
      selectTeacherDialog:false,
      expireTimeOption: {
        disabledDate (date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      dataList:[],
      allList:[],
      treeDataValue:'',
      data: [ ],
      // defaultProps: {
      //   children: 'courseUnitList',
      //   label: 'title'
      // },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roomRules: {
          student: [
            { required: true, message: '请选择试听学员', trigger: 'blur' }
          ],
          treeData: [
            { required: true, message: '请选择试听课节', trigger: 'blur' }
          ],
          radio: [
            { required: true, message: '请选择试听排课方式', trigger: 'blur' }
          ],
          curriculum: [
            { required: true, message: '请选择试听排课', trigger: 'blur'}
          ],
          start_time: [
            { required: true, message: '请选择上课时间', trigger: 'blur' }
          ],
          teacher: [
            { required: true, message: '请选择授课老师', trigger: 'blur' }
          ]
        },
    };
  },
  created(){
    this.initData()
  },
  methods: {
    async getTreeCourseThird () {
      const res = await $getTreeCourseThird({})
      this.data = res.body
    },
    gotoBack(type){
      this.form = {
        radio: '1',
        treeData:'',
        treeDataId:'',
        courseId:'',
        student:[],
        curriculum:'',
        start_time:'',
        teacher:'',
      }
      this.closePage(type)
    },

    closePage(type){
      // var currentView1 = this.$store.state.tagsView.visitedViews[0]
      // for (currentView of this.$store.state.tagsView.visitedViews) {
      //   if (currentView.path === this.$route.path) {
      //     break
      //   }
      // }
      let currentView = this.$store.state.tagsView.visitedViews.find((item, index) => {
          return item.fullPath ==  '/teacher/tryLessonDetail/';
      })
      this.$store.dispatch('tagsView/delView', currentView)
        .then(({ visitedViews }) => {
          if (currentView.path === this.$route.path) {
            const latestView = this.$store.state.tagsView.visitedViews.slice(-1)[0]
            if (latestView) {
              latestView.query = {
                typeDede:type
              }
              console.log(latestView)
              this.$router.push(
                latestView
              )
            } else {
              // 如果没有其他标签则跳转到首页
              if (currentView.name === '首页') {
                this.$router.replace({ path: '/redirect' + currentView.fullPath })
              } else {
                this.$router.push('/')
              }
            }
          }
          this.aduditionDetail = {}
        })
    },

    radioChange() {
      this.form.treeData = ''
      if(this.form.radio == '3'){
        this.form.teacher = ''
      }else if(this.form.radio == '1' ){
        this.form.teacher = ''
        this.form.start_time = ''
      }
      this.$refs.form.clearValidate()
    },

    resetForm(formName) {
      // this.$refs[formName].resetFields();
    },
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.add.initData();
      });
    },
    async getEmployeeList() {
      const res = await getEmployeeList({
        state: 1,
        pageNum:1,
        pageSize: 10000000,
        needCount:true,
      });
      this.allList = res.body.list;
    },
    async getStudentList() {
      const response = await getStudentList({
        pageNum:1,
        pageSize: 10000000,
        needCount:true,
        currentStateSet: ["试听","在读"],
      })
      this.dataList =  response.body.list
    },
    /**
     * 添加成员
     */
    async addMember(value) {
      this.getStudentList()
      const { name, tel, pwd, loginName, state ,sex} = value;
      const response = await addStudent({ name, tel, pwd, loginName, state: '1',currentState:"试听" ,sex});
      if (response.state == "success") {
        this.getStudentList()
        this.$nextTick(()=>{
          this.$message.success("添加成功");
          this.$refs.add.dialogVisible = false;
          let id = response.body.id
          this.form.student.push(id)
        })

      } else {
        this.$message.warning("添加失败");
      }
    },
    aduditionDetailFun(data) {
      this.aduditionDetail = data;
    },
    chooseLesson(data) {
      this.isTagShow = true;
      this.aduditionDetail = data;
      this.form.curriculum =  data.course+'/'+data.courseUnitTitles.title + " " +data.startTime.substring(0,19)
    },
    tagDel () {
      this.isTagShow = false;
      this.form.curriculum = '';
      this.aduditionDetail = {};
      this.aduditionDetail.course = '';
      this.aduditionDetail.startTime = '';
    },
    addStudent() {
      // if (this.studentMessage.name === undefined) {
      //   window.$msg("请先选择学员", 2);
      //   return false;
      // } else if (this.aduditionDetail.course === undefined) {
      //   window.$msg("请先选择课程", 2);
      //   return false;
      // }
      this.$refs.form.validate(async valid => {
        if (valid) {
          // let studentIdsArray = [this.studentMessage.id];
          let addLesson = {
            start_time: this.form.start_time,
            type:"试听",
            teacherIds:[this.form.teacher],
            courseUnitIds:[this.form.treeDataId],
            courseId:this.form.courseId,
            // studentIds:this.form.student
          }
          if(this.form.radio == '3'){
            lessonTryAdd({ ...addLesson }).then(res => {
              this.form.studentIds = res.body
              this.addStudentLessonUnit()
            })
          }else{
            this.addStudentLessonUnit()
          }
        }
      })
    },
    async addStudentLessonUnit(){
      const response = await addStudentLesson({
        lessonId: this.form.radio == '1'?this.aduditionDetail.lessonId : this.form.studentIds,
        studentIds: this.form.student,
        isTry:true
      });
      if (response && response.state === "success") {
        window.$msg("预约试听成功");
        this.gotoBack('submitSuccess');
      }
    },

    chooseStudent(data) {
      this.studentMessage = data;
    },
    selectChange(){

    },
    handleNodeClick(data,node,nodeData){
      if(!data.courseUnitList){
        this.form.treeData = data.name
        this.form.treeDataId =  data.id
        this.form.courseId = data.parentId
        this.treeDataValue= data.id
      }
    },
    // openDialog()
    initData(){
      this.getStudentList()// handleChange
      this.getEmployeeList()
      this.getTreeCourseThird()
      // this.getCourseListAll()
      this.isTagShow = false;
      this.form = {
        radio: '1',
        treeData:'',
        treeDataId:'',
        courseId:'',
        student:[],
        curriculum:'',
        start_time:'',
        teacher:'',
      }
      this.aduditionDetail = {};
      this.studentMessage = {};
    },

    startTimeChange() {
        this.commonTime = ''
        let oldTime = 0
        if(this.form.duration !== undefined) {
          oldTime = (new Date(this.form.start_time)).getTime() + parseInt(this.form.duration) * 60 * 1000;
        }else {
          oldTime = (new Date(this.form.start_time)).getTime()
        }
        if(this.form.start_time !== null) {
          this.form.endTime = formatTate(oldTime);
        }else {
          this.form.endTime = undefined
        }
        this.$forceUpdate()
      },
    teacherData(data){
      console.log(JSON.stringify(data))
      if(data[0].name !== undefined) {
        this.teacherName = data[0].name
        this.form.teacherId = data[0].id
        this.$forceUpdate()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-dialog__body {
  padding-top: 0px;
}
.beginDate {
  margin-left: 20px;
}
.contentClass {
  color: #666666;
  font-weight: normal;
}
.studentClass {
  margin: 15px 0px;
  color: #666666;
  font-weight: bold;
}
.tagClass {
  cursor: pointer;
  margin-right: 10px;
}
.dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 90px;
    margin-right: 38px;
  }
}
.backTop{
  width: 100%;
  background: #fff;
  height: 50px;
  padding-left: 10px;
  line-height: 50px;
  margin-bottom: 20px;
}
.formBox{
  height: calc(100% - 52px);
  padding: 20px 0;
  background: #fff;
}
</style>
