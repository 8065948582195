var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "900px",
        top: "7vh",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dia-tit",
          staticStyle: { "margin-bottom": "0px" },
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("i"),
          _c("span", { staticStyle: { "font-size": "16px" } }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ]
      ),
      _vm.isNewAdudition
        ? [
            _c(
              "el-steps",
              {
                attrs: {
                  active: _vm.active,
                  "align-center": "",
                  "finish-status": "success",
                  simple: "",
                },
              },
              [_c("el-step", { attrs: { title: "新增试听排课" } })],
              1
            ),
          ]
        : _vm._e(),
      _vm.active === 0
        ? [
            _c(
              "el-row",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "formData",
                    attrs: {
                      model: _vm.formData,
                      rules: _vm.rules,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "试听课内容：", prop: "treeData" },
                          },
                          [
                            _c(
                              "el-row",
                              { attrs: { type: "flex", align: "middle" } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { align: "left" } },
                                  [
                                    false
                                      ? _c("el-input", {
                                          model: {
                                            value: _vm.formData.courseId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "courseId",
                                                $$v
                                              )
                                            },
                                            expression: "formData.courseId",
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "20px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.courseMessage.title) +
                                            "/" +
                                            _vm._s(
                                              _vm.courseMessage.courseUnitTitle
                                            )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "授课老师：", prop: "teacherId" } },
                          [
                            false
                              ? _c("el-input", {
                                  on: {
                                    input: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.teacherId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "teacherId", $$v)
                                    },
                                    expression: "formData.teacherId",
                                  },
                                })
                              : _vm._e(),
                            _c("span", [_vm._v(_vm._s(_vm.teacherName))]),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.selectTeacherDialog = true
                                  },
                                },
                              },
                              [_vm._v("选择老师")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "上课时间：", prop: "start_time" },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { float: "left" },
                              attrs: {
                                type: "datetime",
                                "picker-options": _vm.expireTimeOption,
                                format: "yyyy-MM-dd HH:mm",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                placeholder: "选择上课时间",
                              },
                              on: { change: _vm.startTimeChange },
                              model: {
                                value: _vm.formData.start_time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "start_time", $$v)
                                },
                                expression: "formData.start_time",
                              },
                            }),
                            _vm.formData.start_time
                              ? _c("el-row", { staticStyle: { float: "left" } })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.active === 1
        ? [
            _c(
              "el-form",
              {
                ref: "roomFormData",
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  model: _vm.roomFormData,
                  rules: _vm.roomRules,
                  "label-width": "120px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "直播间名称：", prop: "title" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入直播间名称(50个字符以内)",
                        clearable: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                      model: {
                        value: _vm.roomFormData.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomFormData, "title", $$v)
                        },
                        expression: "roomFormData.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "直播日期：", prop: "date" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        "picker-options": _vm.expireTimeOption,
                        clearable: "",
                        disabled: "",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择日期",
                      },
                      model: {
                        value: _vm.roomFormData.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomFormData, "date", $$v)
                        },
                        expression: "roomFormData.date",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "直播时间：", prop: "startTime" } },
                  [
                    _c("el-time-picker", {
                      attrs: {
                        "value-format": "HH:mm:ss",
                        clearable: "",
                        disabled: "",
                        placeholder: "直播起始时间",
                      },
                      model: {
                        value: _vm.roomFormData.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomFormData, "startTime", $$v)
                        },
                        expression: "roomFormData.startTime",
                      },
                    }),
                    _c("span", [_vm._v("—")]),
                    _c("el-time-picker", {
                      attrs: {
                        clearable: "",
                        disabled: "",
                        "value-format": "HH:mm:ss",
                        placeholder: "直播结束时间",
                      },
                      model: {
                        value: _vm.roomFormData.endTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomFormData, "endTime", $$v)
                        },
                        expression: "roomFormData.endTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "直播间类型：", prop: "type" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择直播间类型",
                          clearable: "",
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.roomFormData.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.roomFormData, "type", $$v)
                          },
                          expression: "roomFormData.type",
                        },
                      },
                      _vm._l(_vm.roomType, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "直播间渠道：", prop: "source" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择直播间渠道",
                          clearable: "",
                        },
                        model: {
                          value: _vm.roomFormData.source,
                          callback: function ($$v) {
                            _vm.$set(_vm.roomFormData, "source", $$v)
                          },
                          expression: "roomFormData.source",
                        },
                      },
                      _vm._l(_vm.sourceType, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.roomFormData.type === 4
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开班班型：", prop: "max_users" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                              model: {
                                value: _vm.roomFormData.max_users,
                                callback: function ($$v) {
                                  _vm.$set(_vm.roomFormData, "max_users", $$v)
                                },
                                expression: "roomFormData.max_users",
                              },
                            },
                            _vm._l(_vm.typeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "台下学生：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              oninput: "value=value.replace(/[^\\d]/g,'')",
                              clearable: "",
                              placeholder: "请输入台下学生数,最大为300",
                            },
                            on: { input: _vm.isNum },
                            model: {
                              value: _vm.roomFormData.max_backup_users,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.roomFormData,
                                  "max_backup_users",
                                  $$v
                                )
                              },
                              expression: "roomFormData.max_backup_users",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      [
        _c(
          "el-row",
          { attrs: { slot: "footer" }, slot: "footer" },
          [
            _vm.isNewAdudition
              ? [
                  _vm.active < 2
                    ? _c(
                        "el-button",
                        { attrs: { size: "small" }, on: { click: _vm.next } },
                        [_vm._v("下一步")]
                      )
                    : _vm._e(),
                ]
              : [
                  _c(
                    "el-button",
                    {
                      staticClass: "dialog-btn1",
                      attrs: { type: "primary" },
                      on: { click: _vm.addLessonForm },
                    },
                    [_vm._v("保 存")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "dialog-btn1",
                      attrs: { type: "info" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
          ],
          2
        ),
      ],
      _c("check-time", {
        attrs: { show: _vm.timeDialog, pageFrom: "addadudition" },
        on: {
          close: function ($event) {
            _vm.timeDialog = false
          },
        },
      }),
      _c("room-time", {
        attrs: { show: _vm.roomTimeDialog },
        on: {
          close: function ($event) {
            _vm.roomTimeDialog = false
          },
        },
      }),
      _c("sele-teacher", {
        attrs: {
          teacherId: _vm.formData.teacherId,
          show: _vm.selectTeacherDialog,
        },
        on: {
          teacherData: _vm.teacherData,
          close: function ($event) {
            _vm.selectTeacherDialog = false
          },
        },
      }),
      _c("sele-room", {
        attrs: {
          lessRoom: _vm.lessRoom,
          campusId: _vm.formData.school,
          show: _vm.seleRoomDialog,
        },
        on: {
          seleRoomData: _vm.seleRoomData,
          close: function ($event) {
            _vm.seleRoomDialog = false
          },
        },
      }),
      _c("detection-clash", {
        attrs: { show: _vm.clashDialog, clashData: _vm.clashData },
        on: {
          close: function ($event) {
            _vm.clashDialog = false
            _vm.clashData = []
          },
        },
      }),
      _c("choose-adudition", {
        attrs: {
          show: _vm.chooseAduditionDialog,
          originStudent: _vm.courseMessage,
        },
        on: {
          chooseCourse: _vm.chooseCourse,
          close: function ($event) {
            _vm.chooseAduditionDialog = false
          },
        },
      }),
      _c("add-common-time", {
        attrs: { show: _vm.showCommomTime },
        on: { close: _vm.closeCommonTimeDialog },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }