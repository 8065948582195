<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span style="font-size: 16px;">选择课程</span>
    </div>
    <el-row>
      <el-col :span="5"  style="margin-bottom: 20px;">
      <el-input placeholder="请输入课程名称" v-model="nameorcard"  @keyup.enter.native="search()" > </el-input>
      </el-col>
      <el-col :span="8" :offset="1">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
      >
        <el-table-column label="课程名称" align="center" prop="title" ></el-table-column>
        <!-- <el-table-column label="创建人" align="center" prop="creatorName"></el-table-column> -->
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.createTime | dateFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" v-if="originStudent.id !== scope.row.id" @click="chooseStudent(scope.row)">选择</el-button>
            <el-button type="success" size="mini"  v-if="originStudent.id === scope.row.id">已选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-row>
  </el-dialog>
</template>

<script>
import {getListCourseOnly as getCourseListAll} from '@/api/businesscourse/course'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    originStudent: {
      default: {},
      type: Object
    },
    pageSource: {
      default: "",
      type: String
    }
  },
  data () {
    return {
      dataList: [],
      nameorcard:"",
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  methods: {
    chooseStudent(row){
      this.$emit("chooseCourse",row)
      this.close()
    },
    openDialog () {
          this.nameorcard = ""
          this.getCourseListAll();
    },

    async getCourseListAll () {
      const res = await getCourseListAll({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        courseName: this.nameorcard,
        courseTypes: '试听'
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    close () { this.$emit('close') },

    search(){
      this.pagination.currentPage = 1
      this.getCourseListAll()
    },
    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getCourseListAll()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getCourseListAll()
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/dialog.scss";
@import "../../assets/font/iconfont.css";
.centerCls {
  display: flex;
  align-items: center
}
p {
  line-height: 30px;
}
</style>
