<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span style="font-size: 16px;">选择排课</span>
    </div>
    <el-row>
      <el-col :span="5"  style="margin-bottom: 20px;">
      <el-input placeholder="请输入课程名称" v-model="nameorcard"  @keyup.enter.native="search()" > </el-input>
      </el-col>
      <el-col :span="8" :offset="1">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
      >
        <el-table-column label="试听时间" align="center">
          <template slot-scope="scope">
            {{scope.row.startTime.substring(0,19)}}
          </template>
        </el-table-column>

        <el-table-column label="授课老师" align="center" prop="teacher"></el-table-column>
        <el-table-column label="试听课内容" align="center" >
          <template slot-scope="scope">
            {{scope.row.course}}/{{scope.row.courseUnitTitles.title}}
          </template>
        </el-table-column>
        <el-table-column label="试听人数" align="center">
          <template slot-scope="scope">
          <span style="text-decoration:underline;color: #1c6ca1;cursor: pointer" @click="queryStudent(scope.row)">
            {{scope.row.memberCount}}
            <!-- /{{scope.row.capacity}} -->
          </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" v-if="originLesson.lessonId !== scope.row.lessonId" @click="chooseLesson(scope.row)">选择</el-button>
            <el-button type="success" size="mini"  v-if="originLesson.lessonId === scope.row.lessonId">已选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-row>
    <adudition-lesson-student :show="lessonStudentDialog" :lessonId="lessonId" @close="lessonStudentDialog = false"></adudition-lesson-student>
  </el-dialog>
</template>

<script>
  import {listTryLesson} from '@/api/frontbusiness'
  import aduditionLessonStudent from '@/components/frontbusiness/aduditionLessonStudent'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    originLesson: {
      default: {},
      type: Object
    },
  },
  data () {
    return {
      dataList: [],
      nameorcard:"",
      lessonStudentDialog:false,
      lessonId:"",
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  components: {
    aduditionLessonStudent
  },
  methods: {
    queryStudent(row) {
      if(row.memberCount === 0 || row.memberCount === undefined || row.memberCount === null) {
        window.$msg("此排课中暂无学员",2);
        return false
      }
      this.lessonId = row.lessonId
      this.lessonStudentDialog = true
    },
    chooseLesson(row){
      this.$emit("chooseLesson",row)
      this.close()
    },
    openDialog () {
          this.nameorcard = ""
          this.listTryLesson();
    },

    async listTryLesson() {
      const response = await listTryLesson({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        course: this.nameorcard,
        finishedStatus:'未上课',
      })
      this.dataList = response.body.list
      this.pagination.total = response.body.total
    },

    close () { this.$emit('close') },

    search(){
      this.pagination.currentPage = 1
      this.listTryLesson()
    },
    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.listTryLesson()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.listTryLesson()
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/dialog.scss";
@import "../../assets/font/iconfont.css";
.centerCls {
  display: flex;
  align-items: center
}
p {
  line-height: 30px;
}
</style>
