<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    @open="openDialog"
    width="900px"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit" style="margin-bottom: 0px;">
      <i></i>
      <span  style="font-size: 16px;">{{title}}</span>
    </div>

    <!-- isNewAdudition111：{{isNewAdudition}} -->
    <!-- aduditionObject：{{aduditionObject}}
    <hr />
    formData：{{formData}}
    <hr />
    roomFormData：{{roomFormData}} -->
    <!-- <hr> -->
    <!-- active：{{active}} -->

    <!-- 步骤条 -->
    <template v-if="isNewAdudition">
      <el-steps :active="active" align-center finish-status="success" simple>
        <el-step title="新增试听排课"></el-step>
        <!-- <el-step title="创建直播间"></el-step> -->
      </el-steps>
    </template>

    <!-- 步骤一 -->
    <template v-if="active === 0">
      <el-row style="margin-top: 20px;">
      <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
        <el-row>
          <!-- <el-form-item label="校区："   prop="school">
            <el-select v-model="formData.school" :disabled="selectSchool" placeholder="请选择校区">
              <el-option v-for="item in campusArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item> -->
            <el-form-item label="试听课内容："  prop="treeData">
              <el-row type="flex" align="middle">
                <el-col align="left">
                  <el-input v-if="false" v-model="formData.courseId"></el-input>
                  <span  style="margin-right:20px;">{{courseMessage.title}}/{{courseMessage.courseUnitTitle}}</span>
                  <!-- <el-button size="mini" type="primary" :disabled = "selectSchool"  @click="chooseAduditionDialog = true" >选择课程</el-button> -->
                </el-col>
              </el-row>
              <!-- <el-select
                v-model="formData.treeData"
                placeholder="请选择试听课节"
                style="width:240px"
                clearable
                @change="selectChange"
              >
                <el-option
                  :value="treeDataValue"
                  style="height: auto;font-weight: 400;background: #ffffff;"
                >
                  <el-tree
                    ref="tree"
                    :data="data"
                    default-expand-all
                    node-key="id"
                    :props="defaultProps"
                    @node-click="handleNodeClick"
                  />
                </el-option>
              </el-select> -->
          </el-form-item>
          <el-form-item label="授课老师："   prop="teacherId">
            <el-input v-model="formData.teacherId" @input="$forceUpdate()" v-if="false"></el-input>
            <span>{{teacherName}}</span>
            <el-button style="margin-left: 20px;" size="mini" type="primary" @click="selectTeacherDialog = true" >选择老师</el-button>
            <!-- <el-button type="primary" size="mini"  @click="timeDialog = true" >老师空闲时间</el-button> -->
          </el-form-item>
          <!-- <el-form-item label="教室：">
            <el-input v-if="false" v-model="formData.classRoomId"></el-input>
            <span v-if="classRoomName !== ''">{{classRoomName}}<span v-if="personCount !== '' "  style="font-weight: bold;margin-left: 10px;">[可容纳{{personCount}}人]</span></span>
            <el-button style="margin-left: 20px;" size="mini" type="primary" @click="selectRoom" >选择教室</el-button>
            <el-button type="primary" size="mini"  @click="roomTimeDialog = true" >教室空闲时间</el-button>
          </el-form-item> -->
          <!-- <el-form-item label="计划人数：" prop="number">
            <el-input-number placeholder="请输入计划人数" style="width: 30%;" v-model="formData.number" :min="1" :controls="false"></el-input-number>
          </el-form-item> -->
          <el-form-item label="上课时间：" prop="start_time">
            <el-date-picker
              style="float: left"
              v-model="formData.start_time"
              type="datetime"
              :picker-options="expireTimeOption"
              format="yyyy-MM-dd HH:mm"
              @change="startTimeChange"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择上课时间"
            ></el-date-picker>
            <el-row style="float: left" v-if="formData.start_time">
<!--              <el-dropdown trigger="click" style="margin-left: 10px;"   :disabled="!isTest" v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @command="commonTimeChange">-->
<!--                <span class="el-dropdown-link">-->
<!--                  常用时间段<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--                </span>-->
<!--                <el-dropdown-menu slot="dropdown">-->
<!--                  <el-dropdown-item :command="item"   v-for="(item,index) in commonTimeOptions" :key="index">-->
<!--                    <span style="float: left">{{ item.startTime }}~</span>-->
<!--                    <span style="float: left">{{ item.endTime }}</span>-->
<!--                    <span class="el-dropdown-link" style="float: right;margin-left: 15px" @click.stop="deleteCommonTime(item)">删除</span>-->
<!--                  </el-dropdown-item>-->
<!--                  <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
<!--                </el-dropdown-menu>-->
<!--              </el-dropdown>-->
<!--              <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
<!--              <el-select style="margin-left: 10px;" v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @change="commonTimeChange" placeholder="常用时间段">-->
<!--                <el-option-->
<!--                  v-for="item in commonTimeOptions"-->
<!--                  :key="item.label"-->
<!--                  :label="item.label"-->
<!--                  :value="item.label"-->
<!--                >-->
<!--                  <span style="float: left">{{ item.startTime }}~</span>-->
<!--                  <span style="float: left">{{ item.endTime }}</span>-->
<!--                  <span class="el-dropdown-link" style="float: right" @click.stop="deleteCommonTime(item)">删除</span>-->
<!--                </el-option>-->
<!--                <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
<!--              </el-select>-->
<!--              <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
            </el-row>
          </el-form-item>
<!--          <el-form-item label="上课时长：" prop="duration">-->
<!--            <el-input-number style="width: 20%;" :precision="0" v-model="formData.duration" :min="0" :controls="false" @change="timeDurationhandleChange"></el-input-number>-->
<!--            <span style="margin-left: 10px;margin-right: 30px;">分钟</span>-->
<!--            <el-dropdown trigger="click" style="margin-left: 10px;"   :disabled="!isTest" v-model="timeDurationSelect"  @command="timeDurationSelectChange">-->
<!--            <span class="el-dropdown-link">-->
<!--              常用时长<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--            </span>-->
<!--              <el-dropdown-menu slot="dropdown">-->
<!--                <el-dropdown-item :command="item.value"   v-for="(item,index) in timeDurationOptions" :key="index">-->
<!--                  <span style="float: left">{{ item.label}}</span>-->
<!--                </el-dropdown-item>-->
<!--              </el-dropdown-menu>-->
<!--            </el-dropdown>-->
<!--            <span style="margin-right: 10px;">常用时长</span>-->
<!--            <el-select v-model="timeDurationSelect" placeholder="快捷选择时长" @change="timeDurationSelectChange" clearable>-->
<!--              <el-option-->
<!--                v-for="item in timeDurationOptions"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="结束时间：">-->
<!--            <el-input style="width: 30%;" v-model="formData.endTime" :min="0" disabled></el-input>-->
<!--          </el-form-item>-->
        </el-row>
      </el-form>
      </el-row>
    </template>

    <!-- 步骤二 -->
    <template v-if="active === 1">
      <el-form ref="roomFormData" :model="roomFormData" :rules="roomRules" label-width="120px" style="margin-top: 20px;">
        <el-form-item label="直播间名称：" prop="title">
        <el-input v-model="roomFormData.title" placeholder="请输入直播间名称(50个字符以内)" clearable @input="$forceUpdate()"></el-input>
      </el-form-item>
      <el-form-item label="直播日期：" prop="date">
        <el-date-picker
          v-model="roomFormData.date"
          type="date"
          :picker-options="expireTimeOption"
          clearable
          disabled
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="直播时间：" prop="startTime">
        <el-time-picker
          v-model="roomFormData.startTime"
          value-format="HH:mm:ss"
          clearable
          disabled
          placeholder="直播起始时间">
        </el-time-picker>
        <span>—</span>
        <el-time-picker
          v-model="roomFormData.endTime"
          clearable
          disabled
          value-format="HH:mm:ss"
          placeholder="直播结束时间">
        </el-time-picker>
      </el-form-item>
      <el-form-item label="直播间类型：" prop="type">
        <el-select v-model="roomFormData.type" placeholder="请选择直播间类型" @change="handleChange" clearable>
          <el-option
            v-for="item in roomType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="直播间渠道：" prop="source">
        <el-select v-model="roomFormData.source" placeholder="请选择直播间渠道" clearable>
          <el-option
            v-for="item in sourceType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="roomFormData.type === 4">
        <el-form-item label="开班班型：" prop="max_users">
          <el-select v-model="roomFormData.max_users" placeholder="请选择" @change="$forceUpdate()" clearable>
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="台下学生：">
          <el-input
            oninput="value=value.replace(/[^\d]/g,'')"
            @input="isNum"
            clearable
            v-model="roomFormData.max_backup_users"
            placeholder="请输入台下学生数,最大为300"
          ></el-input>
        </el-form-item>
      </template>
      </el-form>
    </template>

    <!-- 底部按钮 -->
    <template>
      <el-row slot="footer">
        <template v-if="isNewAdudition">
          <!-- <el-button size="small" @click="prev" v-if="active>0">上一步</el-button> -->
          <el-button size="small" @click="next" v-if="active<2">下一步</el-button>
          <!-- <el-button size="small"  @click="close" type="info">取 消</el-button> -->
        </template>
        <template v-else>
          <el-button type="primary" @click="addLessonForm" class="dialog-btn1">保 存</el-button>
          <el-button  @click="close" type="info" class="dialog-btn1">取 消</el-button>
        </template>
      </el-row>
    </template>
    <!-- 老师空闲时间 -->
    <check-time :show="timeDialog" pageFrom="addadudition" @close="timeDialog=false"></check-time>
    <!-- 教室空闲时间 -->
    <room-time  :show="roomTimeDialog" @close="roomTimeDialog=false"></room-time>
    <!-- 选择老师 -->
    <sele-teacher :teacherId="formData.teacherId"  @teacherData="teacherData" :show="selectTeacherDialog" @close="selectTeacherDialog=false"></sele-teacher>
    <!-- 选择教室 -->
    <sele-room :lessRoom = "lessRoom" @seleRoomData="seleRoomData" :campusId = "formData.school" :show="seleRoomDialog"  @close="seleRoomDialog=false"></sele-room>
    <!-- 冲突检测 -->
    <detection-clash :show="clashDialog" @close="clashDialog=false;clashData=[]" :clashData="clashData"></detection-clash>
    <!-- 选择课程 -->
    <choose-adudition :show="chooseAduditionDialog"  @chooseCourse="chooseCourse" :originStudent="courseMessage" @close="chooseAduditionDialog=false"></choose-adudition>
    <add-common-time :show="showCommomTime" @close="closeCommonTimeDialog" ></add-common-time>
  </el-dialog>
</template>

<script>
  import CheckTime from '@/components/senate/check-time'
  import roomTime from '@/components/senate/room-time'
  import seleRoom from '@/components/frontbusiness/sele-room'
  import seleTeacher from '@/components/frontbusiness/sele-teacher'
  import chooseAdudition from '@/components/frontbusiness/chooseadudition'
  import detectionClash from '@/components/senate/detection-clash'
  import { getMyCampus } from '@/api/senate/class'
  import { lessonAdd,lessonTryAdd,lessonEdit ,$getTreeCourseThird } from '@/api/senate/lesson'
  import { formatTate } from '@/utils/formatDate'
  import {listTeacherLevel,deleteUserPeriod} from '@/api/user'
  import addCommonTime from '@/components/senate/add-commontime'
  import { insertClassBliveRoom,deleteClassBliveRoomById,getLiveRoomtnById,updateClassBliveRoomById } from '@/api/senate/class'
  import {getListCourseOnly as getCourseListAll} from '@/api/businesscourse/course'
  export default {
    name: "addAdudition",
    props: {
      show: {
        default: true,
        type: Boolean
      },
      aduditionObject: {
        default: {},
        type: Object
      },
      isNewAdudition: {
        default: false,
        type: Boolean
      },
      isTest:{
        type:Boolean,
        default: ()=>false
      }
    },
    watch: {
      show () {
        if (this.show) {
          if (this.$refs.formData) {
            this.$refs.formData.resetFields()
          }
          if(this.$refs.roomFormData !== undefined) {
            this.$refs.roomFormData.resetFields()
          }
        }
      }
    },
    components: {
      CheckTime,
      roomTime,
      seleRoom,
      seleTeacher,
      chooseAdudition,
      detectionClash,
      addCommonTime
    },
    data () {
      return {
        commonTimeOptions:[],
        showCommomTime:false,
        commonTime:'',
        title:"新增试听排课",
        selectSchool:false,
        clashDialog:false,
        classRoomName:"",
        personCount:"",
        courseId:"",
        
        lessRoom:[],
        clashData:[],
        courseMessage:{},
        timeDialog:false,
        roomTimeDialog:false,
        seleRoomDialog:false,
        selectTeacherDialog:false,
        chooseAduditionDialog:false,
        changeTime:"",
        teacherName:"",
        formData:{
          start_time:"",
          duration:"",
          timeRange: [new Date(2000, 1, 1, 0, 0), new Date(2000, 1, 1, 23, 0)]
        },
        expireTimeOption: {
          disabledDate (date) {
            return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
          }
        },
        campusArray:[],
          sourceType: [{ // 直播间渠道
          value: '校管家V',
          label: '校管家V'
        }, {
          value: 'tms',
          label: 'tms'
        }],
        rules: {
          // school: [{ required: true, message: '请选择校区', trigger: 'change' }],
          teacherId: [{ required: true, message: '请选择授课老师', trigger: 'blur' }],
          // classRoomId: [{ required: true, message: '请选择教室', trigger: 'change' }],
          // number: [{ required: true, message: '请输入计划人数', trigger: 'blur' }],
          start_time: [{ required: true, message: '请输入上课时间', trigger: 'blur' }],
          duration: [{ required: true, message: '请选择上课时长', trigger: 'blur' }],
          time: [
            { required: true, message: '请选择上课日期', trigger: 'change' }
          ],
          timeRange: [
            { required: true, message: '请选择上课时间段', trigger: 'change' }
          ],
          startTime: [
            { required: true, message: '请选择直播时间', trigger: 'change' }
          ]
        },
        active: 0,
        roomType: [{ // 直播间类型
          value: 4,
          label: '小班课'
        }, {
          value: 2,
          label: '大班课'
        }],
        typeOptions: [
          {
            value: 1,
            label: '1V1'
          },
          {
            value: 6,
            label: '1V6'
          }
        ],
        roomFormData: {
          max_backup_users:10
        },
        timeDurationSelect:'',
        timeDurationOptions: [ // 时长
          {
            value: 30,
            label: '30分钟'
          },
          {
            value: 45,
            label: '45分钟'
          },
          {
            value: 60,
            label: '60分钟'
          },
          {
            value: 90,
            label: '90分钟'
          },
          {
            value: 120,
            label: '120分钟'
          },
          {
            value: 180,
            label: '180分钟'
          }
        ],
        roomRules: {
          title: [
            { required: true, message: '请填写直播见名称', trigger: 'blur' },
            { max: 50, message: '字数限制在50个字内', trigger: 'blur' }
          ],
          max_users: [
            { required: true, message: '请选择开班班型', trigger: 'change' }
          ],
          date: [
            { required: true, message: '请选择直播日期', trigger: 'change' }
          ],
          startTime: [
            { required: true, message: '请选择直播时间', trigger: 'change' }
          ],
          type: [
            { required: true, message: '请选择直播间类型', trigger: 'change' }
          ],
          source: [
            { required: true, message: '请选择直播间渠道', trigger: 'change' }
          ]
        },
        lesson_id: '',
        treeDataValue:'',
        data: [ ],
        defaultProps: {
          children: 'courseUnitList',
          label: 'title'
        }
      }
    },
    methods: {
      commonTimeChange(command) {
        if(this.formData.start_time !== undefined){
          this.formData.start_time = this.formData.start_time.substring(0,10)+' '+command.startTime+':00'
          this.formData.endTime = this.formData.start_time.substring(0,10)+' '+command.endTime+':00'
          this.formData.duration = ((new Date(this.formData.endTime)).getTime() - (new Date(this.formData.start_time)).getTime()) / 60 / 1000

          let flag = false
          this.timeDurationOptions.forEach((item) => {
            if (item.value === parseInt(this.formData.duration)) {
              this.timeDurationSelect = this.formData.duration
              flag = true
            }
          })
          if(!flag) {
            this.timeDurationSelect = ''
          }
        }
      },
      async deleteUserPeriod(row) {
        const response = await deleteUserPeriod({
          usperiodId:row.id
        })
        if(response && response.state === 'success') {
          window.$msg('删除成功')
          this.listTeacherLevel()
          this.commonTime = ''
          this.$forceUpdate()
        }
      },
      async listTeacherLevel() {
        const response = await listTeacherLevel()
        if(response && response.state === 'success') {
          this.commonTimeOptions = response.body
        }
        this.commonTimeOptions.forEach((item)=>{
          item.label = item.startTime+'~'+item.endTime
        })
      },
      closeCommonTimeDialog() {
        this.showCommomTime = false
        this.listTeacherLevel()
        this.$forceUpdate()
      },
      deleteCommonTime(row) {
        this.$confirm('确定删除此常用时间段吗?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteUserPeriod(row)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      gotoAddCommenTime() {
        this.showCommomTime = true
      },
      startTimeChange() {
        this.commonTime = ''
        let oldTime = 0
        if(this.formData.duration !== undefined) {
          oldTime = (new Date(this.formData.start_time)).getTime() + parseInt(this.formData.duration) * 60 * 1000;
        }else {
          oldTime = (new Date(this.formData.start_time)).getTime()
        }
        if(this.formData.start_time !== null) {
          this.formData.endTime = formatTate(oldTime);
        }else {
          this.formData.endTime = undefined
        }
        this.$forceUpdate()
      },
      timeDurationhandleChange() {
        this.commonTime = ''
        let flag = false
        this.timeDurationOptions.forEach((item) => {
          if (item.value === parseInt(this.formData.duration)) {
            this.timeDurationSelect = this.formData.duration
            flag = true
          }
        })
        if(!flag) {
          this.timeDurationSelect = ''
        }
        if(this.formData.start_time !== undefined) {
          var oldTime = (new Date(this.formData.start_time)).getTime() + parseInt(this.formData.duration) * 60 * 1000;
          this.formData.endTime = formatTate(oldTime);
          this.$forceUpdate()
        }
      },
      timeDurationSelectChange(command) {
        this.commonTime = ''
        this.timeDurationSelect = command
        this.formData.duration = this.timeDurationSelect
        if(this.formData.start_time !== undefined && this.formData.start_time !== null) {
          if( this.formData.duration !== '') {
            var oldTime = (new Date(this.formData.start_time)).getTime() + parseInt(this.formData.duration) * 60 * 1000;
            this.formData.endTime = formatTate(oldTime);
            this.$forceUpdate()
          }else {
            this.formData.endTime = this.formData.start_time
          }
        }else{
          this.formData.endTime = ''
        }
      },
      selectRoom() {
        if(this.formData.school === undefined || this.formData.school === "" || this.formData.school === null) {
          window.$msg("请先选择校区后在选择教室",2)
          return false
        }
        this.seleRoomDialog=true
      },

      handleChange(val) {
        if (val === 4) {
          this.roomFormData.max_backup_users = 10
        }
      },
      // 新增排课
      addLessonForm () {
        // console.log('addLessonForm', JSON.stringify(this.roomFormData))
        this.$refs.formData.validate(valid => {
          if (valid) {
            // if(this.formData.number > this.personCount && this.personCount != "") {
            //   window.$msg("计划人数超出教室最大容纳人数",2)
            //   return false
            // }

            if(new Date(this.formData.time+' '+ this.formData.startTime).valueOf() > new Date(this.formData.time+' '+ this.formData.endTime).valueOf()) {
              window.$msg('直播结束时间不能小于开始时间',2)
              return false
            }
            // let teacherIds = [this.formData.teacherId]
            let addLesson = {
              start_time: this.formData.start_time,
              // end_time: this.formData.endTime,
              type:"试听",
              // campusId:this.formData.school,
              capacity:this.formData.number,
              // classroom_id:this.formData.classRoomId,
              teacherIds:[this.formData.teacherId],
              courseId:this.formData.courseId,
            }
            if(this.aduditionObject === null) {
              lessonTryAdd({ ...addLesson }).then(res => {
                if (res.state === 'success') {
                  this.roomFormData.title = "【试听】"+this.courseMessage.title +'  '+this.formData.start_time
                  this.roomFormData.date = this.formData.start_time.substring(0, 10)
                  this.roomFormData.startTime = this.formData.start_time.substring(11)
                  let end_time = this.formData.endTime
                  this.roomFormData.endTime = end_time.substring(11)
                  window.$msg('新增排课成功')
                  let aduditionDetail = {}
                  this.lesson_id = res.body
                  aduditionDetail.lessonId = res.body
                  aduditionDetail.course = this.courseMessage.title
                  aduditionDetail.startTime =  this.formData.start_time
                  this.$emit("aduditionDetail",aduditionDetail)
                  if (this.active++ > 1) this.active = 1
                  if (!this.isNewAdudition) {
                    this.close()
                  }
                } else {
                  if (res.errCode === '0201001') {
                    this.clashFun(res)
                  }
                }
              })
            }else {
              // if(this.aduditionObject.memberCount > this.formData.number) {
              //   window.$msg('计划人数不能小于当前已有的排课成员'+this.aduditionObject.memberCount+'人',2)
              //   return false
              // }
              addLesson.id = this.aduditionObject.lessonId
              lessonEdit({ ...addLesson }).then(res => {
                if (res.state === 'success') {
                  window.$msg('编辑排课成功')
                  let aduditionDetail = {}
                  aduditionDetail.lessonId = res.body
                  aduditionDetail.course = this.courseMessage.title
                  this.$emit("aduditionDetail",aduditionDetail)
                  this.close()
                } else {
                  if (res.errCode === '0201001') {
                    this.clashFun(res)
                  }
                }
              })
            }

          }
        })
      },

      // 处理冲突
      clashFun (res) {
        if (res.body && res.body.length > 0) {
          var newArr = [];
          res.body.forEach((item, index) => {
            item.conflictType = item.conflictType +""
            var flag = true
            if (newArr.length > 0) {
              for (var i= 0; i<newArr.length; i++) {
                if (newArr[i].id === item.id && newArr[i].conflictType !== item.conflictType) {
                  // console.log('相同id的冲突')
                  newArr[i].conflictType = [newArr[i].conflictType, item.conflictType],
                    // newArr[i].classroomId = newArr[i].classroomId ? newArr[i].classroomId : item.classroomId,
                    newArr[i].classroomTitle = newArr[i].classroomTitle ? newArr[i].classroomTitle : item.classroomTitle,
                    newArr[i].teacherId = newArr[i].teacherId ? newArr[i].teacherId : item.teacherId,
                    newArr[i].teacherName = newArr[i].teacherName ? newArr[i].teacherName : item.teacherName,
                    newArr[i].teacherType = newArr[i].teacherType ? newArr[i].teacherType : item.teacherType,
                    flag = false
                  break
                }
              }
            }
            if (flag) {
              newArr.push(item)
            }
          })
        }
        this.clashData = newArr
        this.clashDialog = true
      },
      chooseCourse(data) {
        this.courseMessage = data
        this.formData.courseId = data.id
      },
      async getMyCampus () {
        const res = await getMyCampus({})
        this.campusArray = res.body
      },
      teacherData(data){
        console.log(JSON.stringify(data))
        if(data[0].name !== undefined) {
          this.teacherName = data[0].name
          this.formData.teacherId = data[0].id
          this.$forceUpdate()
        }
      },
      seleRoomData(data) {
        this.lessRoom = data
        if(data[0].title !== undefined) {
            this.classRoomName = data[0].title
            this.personCount = data[0].personCount
            // this.formData.classRoomId = data[0].id
        }
      },

      forceUpdate () {
        this.$forceUpdate()
      },
      openDialog () {
        this.active = 0
        // this.getMyCampus()
        this.listTeacherLevel()
        this.getCourseListAll()
        if(this.aduditionObject === null) {
          // this.$nextTick(()=> {
          //   this.$refs.formData.clearValidate()
          // })
          this.title = "新增试听排课"
          this.courseMessage = {}
          // this.selectSchool = false
          this.teacherName = ""
          this.classRoomName = ""
          this.formData = {
            type:"",
            content:"",
            // timeRange: ['00:00:00', '00:00:00']
            timeRange: [new Date(2000, 1, 1, 0, 0), new Date(2000, 1, 1, 23, 0)]
          }
          this.roomFormData = {}
        }else {
          this.title = "编辑试听排课"
          // console.log(JSON.stringify(this.aduditionObject))
          // this.selectSchool = true
          // this.formData.school = this.aduditionObject.campusId
          this.formData.courseId = this.aduditionObject.courseId
          this.courseMessage.title = this.aduditionObject.course
          this.courseMessage.courseUnitTitle = this.aduditionObject.courseUnitTitles.title
          this.teacherName = this.aduditionObject.teacher
          this.classRoomName = this.aduditionObject.classroom
          this.formData.teacherId = this.aduditionObject.teacherId
          // this.formData.classRoomId = this.aduditionObject.classroomId
          this.formData.number = this.aduditionObject.capacity
          this.formData.start_time = this.aduditionObject.startTime.substring(0,19)
          this.formData.endTime = this.aduditionObject.endTime.substring(0,19)
          this.formData.duration = ((new Date(this.aduditionObject.endTime)).getTime() - (new Date(this.aduditionObject.startTime)).getTime()) / 60 / 1000
          let flag = false
          this.timeDurationOptions.forEach((item) => {
            if (item.value === parseInt(this.formData.duration)) {
              this.timeDurationSelect = this.formData.duration
              flag = true
            }
          })
          if(!flag) {
            this.timeDurationSelect = ''
          }
        }
      },
      async getCourseListAll () {
        const res = await getCourseListAll({
          pageNum: 1,
          pageSize: 100000,
          needCount: true,
          courseTypes: '试听'
        })
        this.data = res.body.list
      },
      subtract_hour (a, b) {
        let result = Math.abs((Number(a) - Number(b)))
        // console.log('subtract_hour', result)
        if (result < 10) {
          result = '0' + result
        }
        return result
      },

      subtract_minute (a, b) {
        let result = Math.abs((Number(a) - Number(b)))
        // console.log('subtract_minute', result)
        if (result < 10) {
          result = '0' + result
        }
        return result
      },
      close () {
        this.$emit('close')
      },

      isNum () {
        this.$forceUpdate()
        if (Number(this.roomFormData.max_backup_users) > 300) {
          window.$msg('台下学生数不能大于300', 2)
          this.roomFormData.max_backup_users = ''
        }
      },
      // 下一步
      next () {
        if (this.active === 0) {
          this.addLessonForm()
        } else if (this.active === 1) {
          this.addLiveRoom()
        }
        // if (this.active++ > 1) this.active = 1
        // this.$refs.formData.validate(valid => {
        //   if (valid) {
        //     if (this.active++ > 1) this.active = 1
        //   }
        // })
      },

      // 新增直播间
      async addLiveRoom() {
        await insertClassBliveRoom({
          title: this.roomFormData.title,
          start_time: `${this.roomFormData.date} ${this.roomFormData.startTime}`,
          end_time: `${this.roomFormData.date} ${this.roomFormData.endTime}`,
          type: this.roomFormData.type,
          lesson_id: this.lesson_id,
          max_users: this.roomFormData.type === 4 ? this.roomFormData.max_users : null,
          max_backup_users: this.roomFormData.type === 4 ? this.roomFormData.max_backup_users : null,
          source: this.roomFormData.source,
        })
        window.$msg('新增成功')
        this.close()
      },

      // 上一步
      prev (status) {
        // 重置表单项
        this.formData = {}
        if (status) {
          this.taskTypeNum = 0
          if (this.active-- < 1) this.active = 1
        } else {
          if (this.active-- < 1) this.active = 1
        }
      },
      selectChange(){

    },
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../style/dialog.scss';
  .beginDate {
    margin-left: 20px;
  }
  .studentClass {
    margin:15px 0px;
    color: #666666;
    font-weight: bold;
  }
  .tagClass {
    cursor: pointer;
    margin-right:10px;
  }
  .dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 170px;
    margin-right: 38px;
  }
}
</style>
