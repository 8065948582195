<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    height="90%"
    @open="openDialog"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择教室</span>
    </div>
    <!-- lessRoom：{{lessRoom}} -->
    <el-row :gutter="60">
      <el-col :span="14">
        <el-input
          placeholder="请输入教室名称"
          v-model="inputVal"
          style="width: 200px;margin: 0px 15px 15px 15px;"
          @keyup.enter.native="search"
        >
        </el-input>
        <el-button size="small" type="primary" @click="search">搜 索</el-button>

        <el-table
          style="margin-top: 15px;"
          :data="tableData"
          :row-key="getRowKeys"
          border fit highlight-current-row
          @select="handleSelection"
          @selection-change="handleSelectionChange"
          ref="roomRef"
        >
          <el-table-column type="selection" width="70" align="center" :reserve-selection="true"></el-table-column>
          <el-table-column label="教室名称" align="center" prop="title"></el-table-column>
          <el-table-column label="所在校区" align="center" prop="campusTitle"></el-table-column>
          <el-table-column label="容纳人数" align="center" prop="personCount"></el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </el-col>
      <el-col :span="10">
        <el-row style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;">
          <el-row class="seleRow">
            <span>已选择：</span>
            <span class="seleNum">{{multipleSelectionAll.length}}</span>
          </el-row>
          <ul v-for="(item, index) of multipleSelectionAll" :key="index" class="seleList">
            <li class="sele-item">
              {{item.title}}
              <i
                class="el-icon-delete-solid ico-del"
                @click="delTeacher(index, item.id)"
              ></i>
            </li>
          </ul>
        </el-row>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getClassroomList } from '@/api/senate/room'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lessRoom: {
      default: () => {
        return []
      },
      type: Array
    },
    isMultiple: {
      default: false,
      type: Boolean
    },
    campusId: {
      default: "",
      type: String
    }

  },
  watch: {
    lessRoom: {
      handler (val) {
        // 转换一下目的是为了不被同步
        this.multipleSelectionAll = val
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      tableData: [],
      schoolArray:[],
      selectSchool:false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      inputVal: '',
      multipleSelectionAll: [], // 所有选中的数据包含跨页数据
      multipleSelection: [], // 当前页选中的数据
      idKey: 'id' // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
    }
  },
  methods: {
    getRowKeys (row) {
      return row.id
    },

    // 搜索
    search () {
      this.pagination.currentPage = 1
      this.getClassroomList()
    },

    // 窗口打开回调函数
    openDialog () {
      this.inputVal = ''
      this.pagination.currentPage = 1
      this.multipleSelectionAll = Object.assign([], this.lessRoom)
      this.getClassroomList()
    },

    // 删除老师
    delTeacher (index, id) {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return
      }
      this.multipleSelectionAll.splice(index, 1)
      // 标识当前行的唯一键的名称
      let idKey = this.idKey
      let selectAllIds = id
      for (var i = 0; i < this.tableData.length; i++) {
        if (selectAllIds.indexOf(this.tableData[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.roomRef.toggleRowSelection(this.tableData[i], false)
        }
      }
    },

    // 获取教室列表
    async getClassroomList () {
      const res = await getClassroomList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        status: '已启用',
        campusId: this.campusId,
        title: this.inputVal
      })
      this.tableData = res.body.list
      this.pagination.total = res.body.total

      if (this.lessRoom.length !== 0) {
        let selectAllIds = []
        this.lessRoom.forEach(row => {
          selectAllIds.push(row['id'])
        })

        for (var i = 0; i < this.tableData.length; i++) {
          if (selectAllIds.indexOf(this.tableData[i]['id']) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.roomRef.toggleRowSelection(this.tableData[i], true)
          }
        }
      }
    },

    handleSelection (val) {
      this.multipleSelection = val
    },

    // el-table表格勾选函数
    handleSelectionChange (val) {
      // console.log('handleSelectionChange', val)
      if (this.isMultiple) {
        this.multipleSelectionAll = val
      } else {
        this.multipleSelection = val
        this.multipleSelectionAll = val
        if (val.length > 1) {
          this.$refs.roomRef.clearSelection()
          this.$refs.roomRef.toggleRowSelection(val.pop())
        }
      }
    },

    // 关闭
    close () {
      this.$emit('close')
      this.$refs.roomRef.clearSelection()
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getClassroomList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getClassroomList()
    },

    // 保存
    saveRoleForm () {
      this.$nextTick(() => {
        this.$emit('seleRoomData', this.multipleSelectionAll)
        this.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../style/dialog.scss";
/deep/ .el-table td {
  padding: 10px 0 !important;
}
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  font-size: 15px;
  &:hover {
    color: #f5c319;
  }
}

/deep/ thead {
  .el-table-column--selection {
    .cell {
      display: none;
    }
  }
}
</style>
