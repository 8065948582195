var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("选择排课"),
          ]),
        ]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { "margin-bottom": "20px" }, attrs: { span: 5 } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入课程名称" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search()
                  },
                },
                model: {
                  value: _vm.nameorcard,
                  callback: function ($$v) {
                    _vm.nameorcard = $$v
                  },
                  expression: "nameorcard",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8, offset: 1 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.dataList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "试听时间", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.startTime.substring(0, 19)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "授课老师", align: "center", prop: "teacher" },
              }),
              _c("el-table-column", {
                attrs: { label: "试听课内容", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.course) +
                            "/" +
                            _vm._s(scope.row.courseUnitTitles.title) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "试听人数", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "text-decoration": "underline",
                              color: "#1c6ca1",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.queryStudent(scope.row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.memberCount) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.originLesson.lessonId !== scope.row.lessonId
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseLesson(scope.row)
                                  },
                                },
                              },
                              [_vm._v("选择")]
                            )
                          : _vm._e(),
                        _vm.originLesson.lessonId === scope.row.lessonId
                          ? _c(
                              "el-button",
                              { attrs: { type: "success", size: "mini" } },
                              [_vm._v("已选择")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": _vm.pagination.pageSizes,
              "page-size": _vm.pagination.pageSize,
              layout: _vm.pagination.layout,
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("adudition-lesson-student", {
        attrs: { show: _vm.lessonStudentDialog, lessonId: _vm.lessonId },
        on: {
          close: function ($event) {
            _vm.lessonStudentDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }