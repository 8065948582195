<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span style="font-size: 16px;">排课学生</span>
    </div>
    <el-row>
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
      >
        <el-table-column label="学号" align="center" prop="loginName"></el-table-column>
        <el-table-column label="姓名" align="center" prop="name"></el-table-column>
        <el-table-column label="性别" align="center" prop="sex"></el-table-column>
        <el-table-column label="来源" align="center" prop="source"></el-table-column>
      </el-table>
    </el-row>
  </el-dialog>
</template>

<script>
  import { getLessionStudentMessage} from '@/api/senate/class'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lessonId: {
      default: "",
      type: String
    }
  },
  data () {
    return {
      dataList: [],
    }
  },
  methods: {
    openDialog () {
          this.queryStudent();
    },
    async queryStudent() {
      const res =  await getLessionStudentMessage({
        lesson_id:this.lessonId,
      })
      this.dataList = res.body
    },

    close () { this.$emit('close') },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/dialog.scss";
@import "../../assets/font/iconfont.css";
.centerCls {
  display: flex;
  align-items: center
}
p {
  line-height: 30px;
}
</style>
